/*
* Main Layout
*/
body {
  background-attachment: fixed;
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
#main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.full-height {
  height: 100%;
}
#social-links-container {
  position: fixed;
  top: 1%;
  left: 1%;
  display: flex;
  flex-direction: row;
}
#social-link-container {
  cursor: pointer;
  padding: 0.8em;
  border-radius: 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
}
#social-link-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
#open-side-menu-container {
  position: fixed;
  cursor: pointer;
  top: 1%;
  right: 1%;
  padding: 0.8em;
  border-radius: 0.6em;
}
#open-side-menu-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
#title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;
}
@media only screen and (orientation: portrait) {
  #title-container {
    height: 30%;
    padding-top: 10%;
  }
}
#title-container .title {
  font-weight: bold;
  color: white;
  font-size: 7vw;
}
@media only screen and (orientation: portrait) {
  #title-container .title {
    font-size: 12vw;
  }
}
#player-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
}
@media only screen and (orientation: portrait) {
  #player-container {
    height: 10%;
  }
}
#heart-container {
  display: flex;
  align-items: center;
  padding-left: 5%;
}
#volume-slider-wrapper {
  display: inline-block;
  height: 8em;
  width: 8em;
  padding: 0;
}
#volume-slider-wrapper input {
  transform-origin: 5em 2.3em;
  transform: rotate(-90deg);
}
.icon-sound {
  height: 10vw;
  width: 10vw;
  background-color: Transparent;
  border: none;
  border-radius: 20px;
  padding: 0;
  margin: 0;
  vertical-align: top;
  outline: 0;
}
@media only screen and (orientation: portrait) {
  .icon-sound {
    height: 12vh;
    width: 12vh;
  }
}
.icon-sound img {
  margin: auto;
  display: block;
  height: 85%;
  width: 85%;
  cursor: pointer;
}
.icon-sound img:hover {
  height: 88%;
  width: 88%;
}
.icon-sound img:active {
  height: 83%;
  width: 83%;
}
/*
* Components
*/
.current-track-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}
@media only screen and (orientation: portrait) {
  .current-track-wrapper {
    font-size: 1em;
  }
}
.current-track-wrapper .current-track-container {
  cursor: pointer;
  border-radius: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}
.current-track-wrapper .current-track-container:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.current-track-wrapper .current-track-container:active {
  background-color: rgba(255, 255, 255, 0.05);
}
.current-listeners {
  color: white;
  font-size: 18px;
}
.error-msg {
  color: #9F3A38;
  font-weight: bold;
}
.user-modal-login-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-bottom: 10px;
}
#spotify-button {
  pointer-events: auto !important;
}
/*
 *  Slider
 */
input[type=range] {
  background: transparent;
  -webkit-appearance: none;
  margin: 7.5px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: white;
  border-radius: 5px;
  border: 0px solid white;
}
input[type=range]::-webkit-slider-thumb {
  border: 0px solid white;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ffffff;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: white;
  border-radius: 5px;
  border: 0px solid white;
}
input[type=range]::-moz-range-thumb {
  border: 0px solid white;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  background: white;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 15px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #f2f2f2;
  border: 0px solid white;
  border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
  background: white;
  border: 0px solid white;
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  border: 0px solid white;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  background: white;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: white;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ffffff;
}
