.title {
  font-weight: bold;
  color: white;
  font-size:600%;
}

.title-mobile {
  .title;
  font-size:40px;
}

.sub-title {
  font-weight: bold;
  color: white;
  font-size:200%;
}

.sub-title-mobile {
  .sub-title;
  font-size:35px;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 170px;
}

.title-container-mobile {
  .title-container;
  height: 80px;
}

.jingles-section {
  color: white;
  font-size:120%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.jingles-section-mobile {
  .jingles-section;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.main-jingles-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jingles-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player-jingles-container {
  display: flex;
  align-items: center;
}

.jingles-button {
  display: inline-block;
  color: white;
  font-size:14px;
  height: 130px;
  width: 130px;
  background-color: Transparent;
  border: 1px solid white;
  border-radius: 15px;
  padding: 8px;
  margin: 4px;
  vertical-align: top;
  outline:0;
  cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
}

.jingles-button-mobile {
  .jingles-button;
  font-size:8px;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
}
